import * as React from "react"
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import SideBar from '../components/sidebar';
import UpNextCardNonStrapi from '../components/ui/UpNextCardNonStrapi'
import EnrollNowNav from '../components/nav/EnrollNow'
import SpeakWithAdvisor from '../components/ui/SpeakWithAdvisor'
import phonetertiaryicn from '../assets/img/phone-tertiary-icn.svg';
import cursortertiaryicn from '../assets/img/cursor-tertiary-icn.svg';
import mailtertiaryicn from '../assets/img/mail-tertiary-icn.svg';

const EnrollNow = ({ location }) => (
	<Layout location={location} heading1="Enroll Now" heading2={["We make Medicare easy, ", <span>right from the start.</span>]}>
    	<Seo title="Enroll Now" meta={[{description: 'Through MVP Health Care\'s comprehensive Medicare provider network, you have access to an extensive regional network of doctors, hospitals, and other health care professionals.'}]} bodyclass="enroll subpage" />
		
		<EnrollNowNav />

		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
				
					<main>
						<h3>There are 3 easy ways to enroll in a UVM Health Advantage Plan.</h3>
						
						<div className="enrollment-explained">
							<div className="flexwrap">
								<div>
									<h4>
										<img src={phonetertiaryicn} alt="" width="60" height="60" />
										1. Phone
									</h4>
									<p>Call <span className="phone_desktop"><b>1-833-368-4592</b></span><a className="phone_mobile" href="tel:18333684592"><b>1-833-368-4592</b></a> (TTY 711) to enroll right over the phone in minutes</p>
									<p>October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time.<br />
										April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
									{/* <p>(TTY 711) Seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time<br />
									April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m. </p> */}
								</div>
								<div>
									<h4>
										<img src={cursortertiaryicn} alt="" width="60" height="60" />
										2. Online
									</h4>
									{/* <p><a href="https://www.dialamerica.com/mvp/" className="external">Click here to enroll online</a>.</p> */}
									<p><a href="https://www.dialamerica.com/mvp/" className="external">Enroll Online</a></p>
									<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/mvp-health-care-pre-enrollment-checklist-2024.pdf" className="download">Review the Pre-Enrollment Checklist to understand our plan benefits and rules. (PDF)</a></p>
								</div>
							</div>
							<h4>
								<img src={mailtertiaryicn} alt="" width="60" height="60" />
								3. Mail
							</h4>
							<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/medicare-advantage-individual-enrollment-application-uvm-2024.pdf" className="download">Download the UVM Health Advantage 2024 Application (PDF)</a></p>
							{/* <p>(VT- Addison, Bennington, Caledonia, Chittenden, Essex, Franklin, Grand Isle, Lamoille, Orange, Orleans,  Rutland, Washington, Windham, and Windsor counties NY &ndash; Clinton, Essex, Franklin, Hamilton, St. Lawrence counties)</p> */}
							<p>To complete the application:</p>
							<ol>
								<li key={1}>Select your plan choice on the application.</li>
								<li key={2}>Be sure to fill out one application for each person enrolling.</li>
								<li key={3}>Sign and date the application(s).</li>
								<li key={4}>Send your completed application(s) to:<br />
									<br />
									<b>UVM Health Advantage Medicare Enrollment<br />
									MVP Health Care<br />
									20 S Clinton Ave<br />
									Rochester, NY 14604-1793</b>
								</li>
							</ol>

							<p>IMPORTANT: You must enroll no later than the last working day of the month to meet Medicare&rsquo;s deadline for becoming effective the first day of the next month. For example, if you enroll on January 31, your coverage will begin February 1.</p>

							<p>*Medicare beneficiaries may also enroll in a UVM Health Advantage plan through the CMS Medicare Online Enrollment Center located at <a href="https://www.medicare.gov" className="external">medicare.gov</a>.</p>
						</div>

						<UpNextCardNonStrapi to="/enroll-now/am-i-eligible/">Am I Eligible?</UpNextCardNonStrapi>
						<SpeakWithAdvisor />
					</main>

					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default EnrollNow
